/* Colors */

/* white */
$color-white: #ffffff;

/* black */
$color-black: #000000;
$color-black-opacity-90: rgba(0, 0, 0, 0.9);
$color-black-opacity-70: rgba(0, 0, 0, 0.7);
$color-black-opacity-50: rgba(0, 0, 0, 0.5);
$color-black-opacity-40: rgba(0, 0, 0, 0.4);

/* bootstrap greyscale */
$color-grey-darker: #222222;
$color-grey-dark: #3a3a3a;
$color-grey: #5e5e5e;
$color-grey-light: #acacac;
$color-grey-lighter: #eeeeee;

/* other global colors */
$color-danger: #d93848;
$color-switch-on: #419641;
$color-switch-off: #c12e2a;
$color-warning: #ffa500;
$table-background-gray: #f9f9f9;