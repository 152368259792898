.info-icon {
  cursor: pointer;
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
}

.font-icon-md {
  font-size: 18px;
}