// Dropdown submenu does not work in bootstrap 3 on touch screens. This part of code make it work. There are also some css changes in the fix.js file.
.dropdown-submenu {
	position: relative;
}

.dropdown-submenu > .dropdown-menu {
	top: 0;
	left: 100%;
	margin-top: -6px;
	margin-left: -1px;
	border-radius: 0 6px 6px 6px;
}

.dropdown-submenu:hover > .dropdown-menu {
	display: block;
}

.dropdown-submenu > a::after {
	display: inline-block;
	content: " ";
	float: right;
	margin-left: 5px;
	border-width: 5px 0 5px 5px;
	border-style: solid;
	border-color: transparent transparent transparent #ccc;
}

.dropdown-submenu:hover > a::after {
	border-left-color: #555;
}

.dropdown-submenu.pull-left {
	float: none;
}

.dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
	border-radius: 6px 0 6px 6px;
}
// End of dropdown submenu fix