.button-right {
  float: right;

  @media screen and (max-width: 575px) {
    float: unset;
  }
}

.button-right-sm {
  float: right;

  @media screen and (max-width: 767px) {
    float: unset;
  }
}

.button-right-md {
  float: right;

  @media screen and (max-width: 991px) {
    float: unset;
  }
}

.button-right-lg {
  float: right;

  @media screen and (max-width: 1199px) {
    float: unset;
  }
}

.button-right-xl {
  float: right;

  @media screen and (max-width: 1399px) {
    float: unset;
  }
}

.buttons-layout-lg {
  @media screen and (min-width: 1200px) {
    min-width: 335px;
  }
}

.vertical-offset-to-buttons {
  .btn {
    margin-bottom: 5px;
  }
}

.btn:focus {
  outline: none !important;
}

button:focus{
  outline: none !important;
}