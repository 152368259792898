.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mr-5 {
  margin-right: 5px;
}

.mr-7 {
  margin-right: 7px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-15 {
  margin-right: 15px;
}

.mr-20 {
  margin-right: 20px;
}

.mb-5-md {
  @media screen and (max-width: 991px) {
    margin-bottom: 5px;
  }
}

.mb-5 {
  margin-bottom: 5px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-40 {
  margin-bottom: 40px;
}

.ml-30 {
  margin-left: 30px;
}

.pt-10 {
  padding-top: 10px;
}

.pt-30 {
  padding-top: 30px;
}

.pr-15 {
  padding-right: 15px;
}

.pb-10 {
  padding-bottom: 10px;
}

.pl-15 {
  padding-left: 15px;
}

.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

.py-15 {
  padding-top: 15px;
  padding-bottom: 15px;
}

