@media (min-width: 1400px) {
    .container {
        width: 1370px;
    }
}

.required:after {
  content: " \002731";
  color: red;
  font-size: 10px;
  vertical-align: middle;
}

.general-grey-bar {
    background-color: #f5f5f5;
    margin-top: 50px;
}

.general-grey-bar ul {
    margin-bottom: 0;
    font-size: 12px;
}

#confirm-delete {
    position: absolute;
    top:30% !important;
    margin-top: 0 !important;
}

.form-group {
    position: relative;
}

label.normal {
    font-weight: normal;
}

.checkbox-left-error {
    position: absolute;
    z-index: 10;
    padding: 3px 8px;
    margin-top: 7px;
    border-radius: 4px;
    font-size: 12px;
    bottom: -30px;
    background-color: #f2dede;
    color: #a94442;
    font-weight: 400;
    clear: both;
}
.checkbox-left-error:before{
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-bottom: 6px solid #f2dede;
    width: 0;
    height: 0;
    position: absolute;
    top: -6px;
    left: 7px;
    content: "";
}
.form-group-v-margin-md{
    margin-bottom: 32px;
}
.custom-input {
    height: 15px;
    width: 50%;
}
.global-accessories-addon {
    text-align: left;
}
#equipment .global-accessories-addon {
    width: 60%;
}
#accessories .global-accessories-addon, #accessories-new .global-accessories-addon {
    width: 60%;
}

.remove-text {
    font-size: 0;
}

.height-md {
    min-height: 250px;
}

.height-sm {
    min-height: 150px;
}

.button-next-wrapper {
    text-align: right;

    @media screen and (max-width: 767px) {
        text-align: left;
        margin-top: 15px;
    }
}

.vertical-indent {
    margin-bottom: 3px;
}

.sortable {
    width: 300px;
    padding-left: 0;
}

.sortable li {
    background: #f6f6f6 none repeat scroll 0 0;
    border: 1px solid #ccc;
    color: #1c94c4;
    height: 32px;
    list-style: outside none none;
    margin: 5px;
    padding: 5px;
    cursor: move;
}

.sortable-placeholder {
    border: 1px dashed #ccc !important;
    background-color: #ffffff !important;
}

.sortable-handle:hover {
    cursor: all-scroll;
}

.edit-photo {
    margin: 12px 0;
    
    .img-wrapper {
        display: inline-block;
        background-color: $color-grey-lighter;
        margin-bottom: 15px;
    }
}

.edit-photo textarea {
    height: 80px;
}

.edit-photo .select2 {
    width: 100%;
}

.tagged-images {
    margin: 12px;
    width: 600px;
}

.gallery-frame-square {
    width: 210px;
    height: 210px;
    margin-top: 24px;
    margin-bottom: 40px;
}

.gallery-crop-image {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 198px;
    height: 198px;
    overflow: hidden;
    margin-bottom: 10px;
    position: relative;
    border: 1px solid #cccccc;
    
    a {
        align-self: flex-end;
        margin: 3px;
    }
    
    .cancel-image {
        align-self: flex-end;
    }
}

.file-extension {
    padding: 2px 5px;
    text-transform: uppercase;
    font-weight: 900;
    font-size: 12px;
    color: orange;
    vertical-align: middle;
}

.file-extension.tagged {
    padding: 2px 0;
    color: $color-black
}

.gallery-crop-image-items {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.gallery-frame-square img {
    display: block;
    margin: auto;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: calc(100% - 20px);
}

.extended-left-margin-md {
    margin-left: 20px;
}

.extended-right-margin-md {
    margin-right: 20px;
}

.extended-left-margin-lg {
    margin-left: 30px;
}

.extended-top-margin-lg {
    margin-top: 40px;
}

.extended-bottom-margin-md {
    margin-bottom: 20px;
}

.slider-menu {
    margin-top: 10px;
}

.slider-menu a {
    display: inline-block;
    padding: 0 10px;
    margin-bottom: 10px;

}

li.tag-photo {
    position: relative;
    margin-bottom: 10px;
    padding: 0;
    margin-right: 5px;
    border : 1px solid $color-grey-light;
    background-color: $color-grey-lighter;
    border-collapse: collapse;
}

.tag-photo .btn-delete-img {
    display: none;
    position: absolute;
    right: 10px;
    bottom: 10px;
}
.tag-photo .btn-zoom {
    display: none;
    position: absolute;
    right: 10px;
    top: 7px;
    padding: 4px;
    line-height: 1;
    color: #f6f6f6;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    z-index: 30;
}

.tag-photo .checkbox-select {
    display: none;
    position: absolute;
    right: 4px;
    top: 30px;
    padding: 4px;
    line-height: 1;
    color: #f6f6f6;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2em;
    z-index: 31;
}

.tag-photo .checkbox-select input[type="checkbox"] {
    width: 26px;
    height: 26px;
}


.two-columns {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 30px;
}

.two-columns-md {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 30px;

    @media screen and (max-width: 991px) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        column-gap: 0;
    }
}

.two-columns-lg {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 30px;

    @media screen and (max-width: 1199px) {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        column-gap: 0;
    }
}

.non-breaking-content {
    -webkit-column-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}
.column-fluid-fix {
    height: 70px;
}

li.settings {
    margin: 8px 0px 8px 0px;
}
.dropzone-form .dz-preview .dz-image {
    width: 200px !important;
    height: 200px !important;
    border-radius: 0 !important;
    background-color: $color-grey-lighter;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    
    img {
        width: 100%;
        height: auto;
    }
}

.absolute {
    position: absolute;
}

.select2-dropdown {
    z-index: 10000;
}

.dropzone-form {
    border: 2px dashed rgba(0,0,0,.3);
    background: #fff;
    padding: 54px;
}

.modal-dialog {
    margin: 0;
    width: auto;
}

.modal-content {
    border: none;
}

.modal-scrollable {
    background-color: rgba(0,0,0,0.9); /*dim the background*/
}

@media (min-width: 980px) {
    .modal {
        width: 800px !important;
        margin-left: -400px !important;
    }
}

/* Drag & Drop connected lists */
.dnd-connected-list {
    border: 1px solid #dddddd;
    margin: 12px 0px 20px 0px;
    padding: 12px;
}

.sortable-connected {
    min-height: 120px;
    padding: 0px;
}

.sortable-connected li.video,li.attachment,li.photo {
    background-color: #fdfdfd;
    margin: 4px;
    border: 1px dashed #dddddd;
    list-style: none;
    vertical-align: top;
}

/* combo in input-group-addon */
.input-group-addon.currency {
    padding: 0px;

}

/* combo in input-group-addon */
.input-group-addon.currency select {
    margin: 0px;
    border: 0px;
    width: 80px;
    background-color: #eeeeee;
    height: 32px;
}

/* Backend Configurator */
.configurator-seller li.model {
    border: 1px dotted #dddddd;
    padding: 4px;
    margin: 4px;
    -webkit-border-radius:6px;
    -moz-border-radius:6px;
    border-radius: 6px;
}
.configurator-seller .selected-model {
    border: 1px solid #9ecaed !important;
    outline: none;
    box-shadow: 0 0 10px #9ecaed;
}

.configurator-seller .input-small {
    width: 100px;
    clear: none;
}
#accessories .sortable {
    width: 100%;
}
.cancel-image {
    font-size: 21px;
    cursor: pointer;
    z-index: 2;
}

.translations label {
    font-weight: bold;
}

.translations .row {
    border-bottom: 1px solid #dddddd;
}

.no-horizontal-margin {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

hr {
    border-top: 1px solid #ddd;
}

.container-width-table {
    width: 100% !important;
}

.container-full-hd {
    width: 100%;
    max-width: 1920px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.container-quad-hd {
    width: 100%;
    max-width: 2560px;
    padding-left: 15px;
    padding-right: 15px;
    margin-left: auto;
    margin-right: auto;
}

.container-fluid {
    @media screen and (min-width: 1920px) {
        max-width: 100%;
    }

    @media screen and (min-width: 2560px) {
        max-width: 2560px;
    }
}

.no-photo {
    padding-top: 22px;
}

.custom-focus:focus {
    outline: none !important;
}

.select2-selection {
    border: 1px solid #cccccc !important;
}

.role-label, .label {
    margin: 2px;
}

.translation {
    padding-bottom: 15px;
}
.translation h1 {
    font-size: 32px;
}
.translation h5 {
    margin-top: 25px;
}

.translation .row {
    margin-bottom: 15px;
}

.translation .panel {
    margin-bottom: 0;
}

#cboxLoadedContent {
    overflow-x: hidden !important;
}

/* Sticky footer */
html {
    position: relative;
    min-height: 100%;
}

body {
    margin-bottom: 80px;
}

.footer {
    position: absolute;
    bottom: 0px;
    width: 100%;
    background-color: #f5f5f5;
    text-align: center;
    line-height: 40px;
}
/* End of Sticky footer */

.panel-title {
    display: inline-block;
}

.panel-title span {
    vertical-align: middle;
    line-height: 145%;
    margin-right: 5px;
}

.news-photos img {
    margin: 15px 0 8px 0;
}

.gallery-code {
    padding: 0 3px;
}

.tagged-content {
    padding: 15px;
}

.tagged-images #radio-articles {
    margin-left: 5px;
}

.cancel-image-news {
    top: 16px;
    right: 26px;
}

.translation .panel-heading {
    padding: 6px 15px;
    min-height: 33px;
}

/* Http error messages - admin */
.error-status-admin {
    margin-top: 80px;
    text-align: center;
}
.error-code-admin {
    font-family: inherit;
    font-weight: 200;
    color: #333;
    font-size: 56px;
    margin-bottom: 30px;
}
.error-message-admin {
    font-family: inherit;
    font-weight: 200;
    color: #333;
    font-size: 24px;
    margin-bottom: 60px;
}
.error-homepage-link-admin, .support-message-admin {
    line-height: 24px;
}

.notification-bar {
    position: relative;
    top: 51px;
    background-color: #fff761;
    text-align: center;
    font-size: 12px;
    padding: 5px 15px;
}

.notification-bar-alt {
    background-color: #bc0f23;
    color: #ffffff;
}

.notification-bar-alt  a {
    color: #ffffff;
}

.truck-content {
    font-size: 12px;
}

.truck-content .order {
    float: right;
    font-size: 10px;
}


.truck-content-cell {
    background-image: url('/dist/images/backend/truck.jpg');
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 135px;
}

.truck-content {
    margin-top: 6px;
    margin-left: 100px;
    height: 84px;
}

.truck-content li {
    float: left;
    margin-left: 2px;
    margin-top: 2px;
    border: 1px solid #888888;
    border-radius: 4px;
    padding: 4px;
    height: 78px;
    width: 32%;
    white-space: normal;
}

.truck-cell-height {
    min-height: 120px;
    width: 1px;
}

pre {
    white-space: pre-wrap;
    white-space: -moz-pre-wrap;
    white-space: -o-pre-wrap;
    word-wrap: break-word;
}

form + table{
    margin-top:20px;
}

.accessories-group td {
    background-color: #000000 !important;
    color: #ffffff !important;
}

.link-pointer {
    cursor: pointer;
}

.table-head-text-top thead tr th {
    vertical-align: top;
}


#scroll-to-top {
    display: none;
    position: fixed;
    z-index: 10001;
    bottom: 10px;
    right: 15px;
}

#scroll-to-top a {
    display: block;
    width: 48px;
    height: 48px;
    color: #ffffff;
    text-decoration: none;
    font-size: 48px;
    text-align: center;
    background-color: rgba(0,0,0,0.3);;
}

#scroll-to-top a:hover {
    background-color: rgba(0,0,0,0.5);
}

#scroll-to-top i {
    vertical-align: top;
}

/* Fix wysiwyg border */
.mce-edit-area {
    border-right: 1px solid #cacaca !important;
}

.checkboxes-close .checkbox, .checkboxes-close .radio {
    margin-top: 0;
    margin-bottom: 5px;
}

/* Set not allowed cursor to label of disabled checkbox */
.checkbox input[type=checkbox]:disabled+label {
    cursor: not-allowed;
}

/* Set not allowed cursor to label of disabled radio */
.checkbox input[type=radio]:disabled+label {
    cursor: not-allowed;
}

.inline-block {
    display: inline-block;
}

.search-box-section {
    float: right;
}

@media screen and (max-width: 991px) {
    .search-box-section {
        float: unset;
    }
}

.order-documents-block {
    position: relative;
}

.order-documents-block .order-documents-link {
    display: block;
    width: 84px;
}

.order-documents {
    display: none;
    position: absolute;
    z-index: 900;
    top: -4px;
    left: 91px;
    width: auto;
    min-width: 275px;
    height: auto;
    background-color: #ffffff;
    border: 1px solid #dddddd;
    padding: 5px 15px;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -webkit-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    -moz-box-shadow: 0 6px 12px rgba(0,0,0,.175);
    font-size: 13px;
}

.order-documents:before {
    position: absolute;
    content: "";
    top: 5px;
    left: -8px;
    width: 0;
    height: 0;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    border-right: 8px solid #dddddd;
}

.order-documents:after {
    position: absolute;
    content: "";
    top: 6px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 7px solid #ffffff;
}

.order-documents i {
    min-width: 12px;
    margin-right: 3px;
    color: #337ab7;
}

.order-documents i.fa-file-pdf-o {
    color: #d9534f;
}

.order-documents i.fa-file-pdf {
    color: #d9534f;
}

.order-documents .document-link {
    display: inline-block;
    padding: 5px 0;
}

.order-documents-link-pressed {
    background-color: #265a88;
    background-position: 0 -15px;
}

.order-documents .dropdown-toggle {
    cursor: pointer;
    box-shadow: none !important;
}

.documents-close {
    position: absolute;
    display: flex;
    cursor: pointer;
    color: #dddddd;
    right: 5px;
    top: 5px;
    width: 15px;
    height: 15px;
    justify-content: center;
    align-items: center;
    text-decoration: none !important;
}

.documents-close:hover {
    color: #aaaaaa;
}

.documents-close i {
    margin-right: 0;
    min-width: unset;
    color: unset;
}

.button-toggle-group {
    margin-bottom: 5px;
}

.button-toggle-group a {
    text-decoration: none;
}

.button-toggle-group a span:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: none;
}

.button-toggle-group a span:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    border-left: none;
    margin-left: -4px;
}

.button-toggle-group .btn-danger:not(.active):not(.custom), .button-toggle-group .btn-success:not(.active):not(.custom) {
    color: #333 !important;
    background: #fff linear-gradient(to bottom, #fff 0, #e0e0e0 100%) !important;
    text-shadow: 0 1px 0 #fff !important;
}

.button-toggle-group a .btn-danger:not(.active):not(.custom):first-child, .button-toggle-group a .btn-success:not(.active):not(.custom):first-child {
    border-left: 1px solid #888;
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
}

.button-toggle-group a .btn-danger:not(.active):not(.custom):last-child, .button-toggle-group a .btn-success:not(.active):not(.custom):last-child {
    border-right: 1px solid #888;
    border-top: 1px solid #888;
    border-bottom: 1px solid #888;
}

.highlight-target {
    background-color: #fffa9b !important;
}

.contact-line {
    line-height: 3em;
    border-bottom: 1px solid #dddddd;
}

.header-line {
    line-height: 3em;
    border-bottom: 2px solid #dddddd;
}

.custom-checkbox-lg {
    height: 20px;
    width: 20px;
    margin: 0 !important;
    cursor: pointer;
}

.center-flex {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 0;
}

.vertical-center-flex {
    display: flex;
    align-items: center;
}

.flex-middle-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 10px;
    padding-bottom: 10px;
}

.flex {
    display: flex;
}

.justify-between {
    justify-content: space-between;
}

.label-lg {
    font-size: 18px;
    font-weight: 500;
}

.label-inline {
    margin-bottom: 0;
    margin-left: 10px;
    vertical-align: top;
    cursor: pointer;
}

.inquiry-key {
    font-weight: 900;
}

.colorbox-form-wrapper {
    padding: 20px 30px;
}

#copy-accessories-form {
    .form-group {
        .checkbox {
            margin-top: 0 !important;
            margin-bottom: 5px;
        }
    }
}

.search-panel {
    display: inline-block;

    @media screen and (max-width: 767px) {
        display: block;
    }
}

.tooltip-inner {
    max-width: 100%;
}

.panel-heading h4 {
    margin: 0;
    font-size: 16px;
}

.panel-body.table-organized-data {
    line-height: 2em;
}

.translation-menu {
    padding: 10px 10px 5px 10px;
    white-space: normal;
    min-width: unset;

    a {
        margin-bottom: 5px;
        width: 80px;
    }
}

.website-toggles-dropdown {
    padding: 10px;
    white-space: nowrap;
}

.toggle-switch-group {
    .toggle-switch {
        margin-bottom: 0;
    }
}

.dropdown-top {
    top: unset;
    bottom: 40px;
}

.link-disabled {
    pointer-events: none;

    span {
        color: $color-grey-light;
    }
}

.element-disabled {
    pointer-events: none;
    opacity: 0.5;
}

.text-bold {
    font-weight: 900 !important;
}

.datepicker-input {
    width: 100% !important;
}

.show-md {
    display: none;

    @media screen and (max-width: 1199px) and (min-width: 767px){
        display: block;
    }
}

.add-item-message {
    color: $color-danger;
    margin-top: 15px;
}

.border-bottom-offset-5 {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 5px;
}

.switch-on {
    color: $color-switch-on;
}

.switch-off {
    color: $color-switch-off;
}

.color-text-danger {
    color: $color-danger;
}

.color-text-warning {
    color: $color-warning;
}

#cboxLoadedContent.background-grey-lighter {
    background-color: $color-grey-lighter;
}

.background-grey-lighter {
    background-color: $color-grey-lighter;
}

.internal-article-row {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.internal-article-info {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
}

.internal-article-info-wrapper {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: 0%;
    border: 2px solid $color-grey-lighter;
    padding: 15px;
    
    @media screen and(max-width: 991px) {
        margin-top: 15px;
    }
}

.internal-article-placeholder-wrapper {
    border: 2px solid $color-grey-lighter;
    aspect-ratio: 3/2;
    
    a {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        
        img {
            width: 80%;
            height: 80%;
        }
    }
}

.hide-md {
    display: block;

    @media screen and (max-width: 991px) {
        display: none;
    }
}

.internal-article-description {
    margin: 15px 0 30px 0;
    font-weight: 600;
    color: $color-grey;
}

.internal-article-detail-image-wrapper {
    margin-bottom: 30px;
}

.internal-article-detail-text {
    margin: 30px 0;
    line-height: 1.6em;
}

.internal-article-read-more {
    padding-left: 45px;
    
    @media screen and (max-width: 991px) {
        padding-left: 15px;
    }
    
    h3 {
        margin-top: 0;
    }
    
    ul {
        margin-top: 15px;
        padding-left: 20px;
        
        li {
            margin-bottom: 5px;
        }
    }
}

.internal-article-video {
    margin-bottom: 30px;
}

.darken {
    display: inline-block;
    background: black;
    
    img {
        display: block;
        transition: all 0.4s linear;
        
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -ms-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
        -webkit-backface-visibility: hidden;
        -webkit-transform: translateZ(0) scale(1.0, 1.0);
    }
    
    .text {
        color: transparent;
        opacity: 0;
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transition: background-color 0.5s;
        padding: 12px;
        line-height: 150% !important;
    }
    
    &:hover {
        img {
            opacity: 0.5;
        }
        .text {
            opacity: 1;
            color: white;
            -webkit-transition: all 0.4s linear;
            -moz-transition: all 0.4s linear;
            -ms-transition: all 0.4s linear;
            -o-transition: all 0.4s linear;
            -webkit-backface-visibility: hidden;
            -webkit-transform: translateZ(0) scale(1.0, 1.0);
        }
    }
}

.play-arrow {
    position: relative;
    
    a:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 60px;
        height: 60px;
        content: "";
        opacity: 0.5;
        background-image: url('/dist/images/play-sm.png');
    }
    
    a:hover:after {
        opacity: 1;
        -webkit-transition: all 0.4s linear;
        -moz-transition: all 0.4s linear;
        -ms-transition: all 0.4s linear;
        -o-transition: all 0.4s linear;
    }
}

.dashboard-internal-article {
    display: flex;
    margin-bottom: 10px;
    
    @media screen and (max-width: 575px) {
        flex-direction: column;
    }
  
    
    &:first-child {
        margin-bottom: 15px;
        
        .dashboard-internal-article-text-wrapper {
            h5 {
                font-size: 18px;
                font-weight: 600;
                margin-top: 5px;
                margin-bottom: 5px
            }
        }
    }
}

.dashboard-internal-article-img-wrapper {
    flex-shrink: 0;
    
    img {
        @media screen and (max-width: 575px) {
            margin-bottom: 10px;
        }
    }
}

.dashboard-internal-article-text-wrapper {
    h5 {
        margin: 0;
    }
}

.dashboard-internal-article-text-wrapper.extra-padding {
    padding-left: 15px;
    
    @media screen and (max-width: 575px) {
        padding-left: 0;
    }
}

.inline-input-responsive {
    margin-right: 10px;
    
    @media screen and (max-width: 767px) {
        margin-right: 0;
    }
}

.striped-rows {
    .row:nth-child(odd) {
        background-color: $color-grey-lighter;
    }
}

.border-right-grey {
    border-right: 1px solid $color-grey-light;
}

.panel-text {
    font-size: 13px;
    line-height: 1.5em;
}

.panel-body.collapse, .panel-body.collapsing {
    padding-top: 0;
    padding-bottom: 0;
}

.all-align-center {
    text-align: center;
    vertical-align: middle !important;
}

.toggle-active-link {
    display: inline-block;
    padding: 0.5em;
}
