.form-group {
  .select2-container {
    width: 100% !important;

    ul {
      width: 100%;
    }
  }

  .select2-selection__rendered {
    padding-left: 12px !important;
    line-height: 34px !important;
  }

  .select2-selection--single {
    height: 34px !important;
  }

  .select2-selection__arrow {
    height: 34px !important;
  }
}