@import "variables";
@import "general";
@import "partials/menu";
@import "partials/breadcrumbs";
@import "print";
@import "select2-bs-adjustment";
@import "errors/errors";
@import "validations/validations";
@import "global-corrections";
@import "partials/add-items";
@import "partials/icons";
@import "partials/data-tables";
@import "partials/popovers";
@import "partials/buttons";
@import "partials/labels";
@import "text-format";
@import "views-responsive";
@import "offsets";
@import "partials/tables";
@import "touch-menu-fix";
@import "partials/custom-modal";
@import "file-upload";