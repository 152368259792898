.error-page {
  text-align: center;
  padding-top: 144px !important;

  @media screen and (max-width: 991px) {
    padding-top: 20px !important;
  }

  h1 {
    margin-bottom: 20px;
  }

  p {
    font-size: 20px;
    line-height: 1.5em;
  }
}