.colorbox-bs {
	#cboxLoadedContent {
		border: none;
		border-radius: 6px;
		outline: 0;
		-webkit-box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
		box-shadow: 0 5px 15px rgba(0, 0, 0, .5);
		width: 600px;
		
		@media (max-width: 767px) {
			width: 90vw;
		}
	}
}

.custom-modal-content {
	border-radius: 6px;
	background-color: $color-white;
	border: 1px solid $color-grey-dark;
	outline: 0;
	box-shadow: 0 5px 15px $color-black-opacity-50;
}

.custom-modal-header {
	padding: 15px;
	border-bottom: 1px solid #e5e5e5;
	
	h4 {
		margin: 0;
		font-size: 18px;
	}
	
	.close {
		float: right;
		font-size: 21px;
		font-weight: 700;
		line-height: 1;
		color: #000;
		text-shadow: 0 1px 0 $color-white;
		opacity: .2;
		
		&:hover {
			color: $color-black;
			text-decoration: none;
			cursor: pointer;
			opacity: .5;
		}
	}
}

.custom-modal-body {
	margin: 20px 0;
	padding: 15px;
}

.custom-modal-footer {
	padding: 15px;
	text-align: right;
	border-top: 1px solid #e5e5e5;
}