.gallery-labels-wrapper {
  position: absolute;
  float: left;
  padding: 4px;
}

.gallery-labels .label {
  font-weight: 400;
  opacity: 0.75;
  margin-right: 2px;
  max-width: 190px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  vertical-align: middle;
}

.gallery-labels .label-brand {
  background-color: #d9534f;
}

.gallery-labels .label-model {
  background-color: #337ab7;
}

.gallery-labels .label-trim {
  background-color: #5cb85c;
}

.gallery-labels .label-engine {
  background-color: #f0ad4e;
}

.gallery-labels .label-color {
  background-color: #5bc0de;
}

.gallery-labels .label-article {
  background-color: #fff153;
  color: black;
}
.gallery-labels .label-page {
  background-color: #bfe19a;
  color: black;
}
.gallery-labels .label-accessories {
  background-color: #cccccc;
  color: black;
}

.gallery-labels .label-equipment {
  background-color: #e083ff;
  color: black;
}

.gallery-labels .label-person {
  background-color: #084808;
  color: #ffffff;
}

.gallery-labels .label-company {
  background-color: darkgoldenrod;
  color: #000000;
}

.gallery-labels .label-gallery_tile {
  background-color: #814c17;
  color: #ffffff;
}

.gallery-labels .label-domain {
  background-color: #2e73fc;
}

.gallery-labels .label-unassigned {
  background-color: #000000;
}

.vertically-offset-labels {
  label {
    margin-bottom: 15px;
  }
}