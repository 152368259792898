.dataTables_wrapper {
  @media screen and (max-width: 767px) {
    margin-top: 30px;
  }

  table {
    width: 100% !important;

    @media screen and (max-width: 767px) {
      text-align: left;
    }
  }
}

table.dataTable .select-wrapper select {
  width: 100%;
  margin-top: 5px;
}

table.dataTable th select, table.dataTable th option {
  font-weight: normal !important;
  font-size: 1em;
  padding: 2px;
}

table.dataTable.nowrap th, table.dataTable.nowrap td {
  white-space: nowrap;
}

div.dataTables_paginate ul.pagination {
  margin: 15px 0 0 0;

  @media screen and (max-width: 767px) {
    margin: 5px 0 0 0;
  }
}

div.dataTables_info {
  padding-top: 0;
  margin-top: 15px;
}