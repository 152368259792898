@media print {
    body {
        margin: 0;
        color: #000000;
        background-color: #ffffff;
    }

    footer {
        display: none;
    }

    h1 {
        font-size: 28px;
    }

    #breadcrumbs {
        display: none;
    }

    i.fa {
        display: none;
    }

    div.dataTables_filter,
    div.dataTables_info,
    div.dataTables_length,
    div.dataTables_paginate
    {
        display: none;
    }

    table.dataTable thead .sorting:after,
    table.dataTable thead .sorting_desc:after,
    table.dataTable thead .sorting_asc:after
    {
        display: none;
    }

    .label {
        border: none;
        padding: 0;
    }

    #orders-table_wrapper select {
        display: none;
    }

    #scroll-to-top {
        display: none;
    }

    .media-print-hide {
        display: none;
    }
}

