.popover {
  .close {
    font-size: 15px;
  }
}

.popover-nowrap {
  white-space: nowrap;
}

.custom-popover-wrapper {
  .popover-exchange-rates {
    color: $color-grey-dark;
  }

  .popover-title {
    color: $color-grey-dark;
  }
}
