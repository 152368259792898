.white-space-nowrap {
	white-space: nowrap;
}

table.structured-table {
	table {
		font-size: 11px;
		margin-top: 20px;
		width: calc(100% - 40px);
		margin-left: 20px;
	}
}

.table-with-shadow {
	box-shadow: 5px 5px 14px 3px rgba(0,0,0,0.2);
	-webkit-box-shadow: 5px 5px 14px 3px rgba(0,0,0,0.2);
	-moz-box-shadow: 5px 5px 14px 3px rgba(0,0,0,0.2);
}

.table-background-gray {
	background-color: $table-background-gray;
}

.td-w-narrow {
	width: 80px;
}

.td-w-middle {
	width: 200px;
}

.td-w-20 {
	width: 20%;
}