#breadcrumbs ul {
  padding: 0;
  list-style: none;
  height: 28px;
  display: flex;
  align-items: center;
  margin: 0;
}

#breadcrumbs li {
  float: left;
  line-height: 20px;
  font-size: 13px;
  font-weight: 400;
  white-space: nowrap;
  margin-right: 5px;
}