// Global
.admin-top-header {
  margin-bottom: 20px;
  font-size: 32px;

  @media screen and (max-width: 767px) {
    text-align: center;
    font-size: 28px;
  }
}

.admin-table-help-text {
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.admin-top-header span {
  font-size: 32px;
}

.admin-top-header a {
  margin-right: -5px;
}

.admin-section-header {
  margin-top: 10px;
  margin-bottom: 10px;
}

.flex-row {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

.flex-center-sm {
  @media screen and (max-width: 767px) {
    justify-content: center;
    align-items: center;
  }
}

.flex-row {
  &:before, &:after {
    display: none;
  }
}

.form-buttons {
  text-align: right;
}

.form-buttons-sm {
  text-align: right;

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  a, button {
    @media screen and (max-width: 767px) {
      margin-bottom: 5px;
    }
  }
}

.form-buttons-left-sm {
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

// Lists with tables
.touch-devices-scroll-detect {
  display: none;
  margin-bottom: 15px;
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }

  span {
    display: none;

    @media (hover: none) and (pointer: coarse) {
      display: inline-block;
    }
  }
}

.alert.alert-info, .alert.alert-warning, .alert.alert-danger, .alert.alert-success  {
  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.view-info {
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}

.gallery-description {
  padding-right: 30px;
  padding-bottom: 5px;
  padding-top: 10px;
}

.sr-block {
  @media screen and (max-width: 767px) {
    display: block;
  }
}

.accessories-import-warning {
  line-height: 1.6em;

  @media screen and (max-width: 767px) {
    margin-top: 15px;
  }
}

.switch-box {
  min-width: 200px;
}

.nav.nav-tabs>li:not(.active)>a {
  border-top: 1px solid #dddddd;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  border-bottom: 1px solid #dddddd;
}

.nav.nav-tabs>li {
  position: relative;
  margin-top: 5px;

  &:after {
    position: absolute;
    content: "";
    bottom: 0;
    right: -2px;
    width: 5px;
    height: 1px;
    background-color: #dddddd;
  }
}

.nav.nav-tabs>li.active>a {
  color: $color-grey-dark;
}

.currency-form {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  flex-direction: row;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }
}

.configurator-currency-ratio {
  h4 {
    @media screen and (max-width: 575px) {
      margin: 0;
    }
  }
}

.configurator-currency-ratio-item {
  display: inline-block;
  margin-right: 15px;

  @media screen and (max-width: 575px) {
    display: block;
    line-height: 2em;
  }

  span {
    margin-right: 5px;
  }
}

.form-group-custom {
  margin-bottom: 20px;
}

.form-group-custom-md {
  @media screen and (max-width: 991px) {
    margin-bottom: 40px;
  }
}

.index-page-description {
  text-align: left;

  @media screen and (max-width: 767px) {
    text-align: center;
  }
}