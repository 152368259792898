.logo-home-link {
  padding-top: 9px !important;
  padding-bottom: 9px !important;
  padding-left: 0 !important;

  @media screen and (max-width: 1199px) {
    padding-top: 10px !important;
    padding-bottom: 10px !important;
    padding-left: 30px !important;
  }

  span {
    display: none;

    @media screen and (max-width: 1199px) {
      display: inline-block;
    }
  }

  .admin-logo {
    @media screen and (max-width: 1199px) {
      display: none;
    }
  }
}

.admin-logo-sr {
  display: none;

  @media screen and (max-width: 1199px) {
    display: inline-block;
    padding-top: 9px;
    padding-bottom: 9px;
  }
}

.header-currency {
  color: #9d9d9d;
  font-size: 14px;
  padding: 5px 15px;

  @media screen and (max-width: 1199px) {
    padding: 0 15px 0 15px;
    text-align: right;
  }
}

.menu-item-inner-wrapper {
  @media screen and (max-width: 1199px) {
    padding: 5px 15px 5px 15px;
    border-top: 1px solid #101010;
    box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.1);
  }
}

.currency-ratio {
  display: inline-block;
  width: 35px;
  border-bottom: none !important;
  text-align: right;

  @media screen and (max-width: 1199px) {
    text-align: left;
  }
}

.currency-ratio-info {
  padding: 0 8px;
  font-size: 18px;
  line-height: 50px;

  &:last-child {
    padding-right: 15px;
  }

  @media screen and (max-width: 1199px) {
    line-height: 40px;
    padding: 0 15px 0 15px;
    text-align: right;
  }
}

.header-currency span {
  border-bottom: 1px dotted;
}

//Change menu to menu mobile at 1200px instead of 768px
.navbar-fixed-top .navbar-collapse {
  max-height: unset;

  @media screen and (max-width: 767px) {
    padding-left: 0 !important;
    padding-right: 0 !important

  }
}


@media screen and (max-width: 1199px) {
  .navbar-header {
    float: none;
  }

  .navbar-left,
  .navbar-right {
    float: none !important;
  }

  .navbar-toggle {
    display: block;
    margin-right: 0;
  }

  .navbar-collapse {
    border-top: 1px solid transparent;
    box-shadow: inset 0 1px 0 rgba(255,255,255,0.1);

  }

  .navbar-fixed-top {
    top: 0;
    border-width: 0 0 1px;
  }

  .navbar-collapse.collapse {
    display: none !important;
    overflow: visible !important;
    visibility: visible !important;
  }

  .navbar-nav {
    float: none!important;
    margin-bottom: 0;
  }

  .navbar-nav.navbar-right {
    margin-top: 0;
  }

  .navbar-nav>li {
    float: none;
  }

  .navbar-nav>li>a {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 30px;
  }

  .collapse.in{
    display:block !important;
  }

  .navbar .navbar-nav {
    margin-left: -15px;
    margin-right: -15px;
  }

  .navbar-nav .open .dropdown-menu {
    position: static;
    float: none;
    width: auto;
    margin-top: 0;
    background-color: $color-white;
    border: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
  }

  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: rgb(119, 119, 119);
    padding: 5px 15px 5px 25px;
  }

  .dropdown-menu > li >a {
    color: $color-grey-dark !important;
  }

  .navbar-nav .open .dropdown-menu>li>a {
    padding: 5px 15px 5px 40px;
  }
}

@media screen and (min-width: 768px) {
  .navbar .container {
    margin-left: auto;
    margin-right: auto;
    padding: 0;
    max-width: 1170px;
    width: initial;
  }

  .navbar > .container .navbar-brand {
    margin-left: 0;
  }

  .navbar .container .navbar-header {
    margin-left: 0;
    margin-right: 0;
  }
}

.scrollable-menu {
  height: auto;
  max-height: 400px;
  overflow-y: auto;
}