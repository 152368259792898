// primevue v2.10.2 file upload https://www.primefaces.org/primevue-v2/#/fileupload
.p-button {
	font-size: 14px;
	font-weight: 700;
	
	.pi {
		font-size: 14px;
	}
}

.p-fileupload {
	font-size: 14px;
}

.p-fileupload-buttonbar {
	font-size: 14px;
}

.p-button.p-button-icon-only {
	width: 32px;
	height: 32px;
	padding: .5rem;
}

.p-fileupload-row > div {
	width: unset !important;
	flex-grow: unset !important;
	flex-shrink: unset !important;
	flex-basis: auto !important;
	
	&:first-child {
		padding: 0 !important;
	}
}

.p-fileupload-row > div.p-fileupload-filename {
	flex-grow: 2 !important;
}

.fileupload-area {
	text-align: center;
	
	.cloud-upload-icon {
		font-size: 3rem;
	}
	
	.primary-upload-hint {
		font-weight: 700;
		margin-bottom: 10px
	}
	
	.secondary-upload-hint {
		font-size: 12px
	}
}

.custom-file-upload {
	display: flex;
	flex-direction: column;
	
	.p-fileupload-buttonbar {
		order: 2;
		display: flex;
		align-items: baseline;
		justify-content: space-between;
		border: 1px solid #dee2e6;
		border-top: 0 none;
		border-radius: 0 0 3px 3px;
		
		.p-button {
			margin-right: 0;
		}
		
		.p-button:nth-child(2) {
			background-color: #28a745;
			border: 1px solid #28a745;
			padding: 1rem 2rem;
			font-size: 16px;
		}
	}
	
	.p-fileupload-content {
		order: 1;
		border-radius: 3px 3px 0 0;
	}
	
}
