label.error, span.error-help-block {
  position: absolute;
  z-index: 10;
  padding: 3px 8px;
  margin-top: 7px;
  border-radius: 4px;
  font-size: 12px;
  right: 0;
  bottom: -34px;
  background-color: #f2dede;
  color: #a94442;
  font-weight: normal;
  clear: both;
}

span.error-help-block {
  bottom: -40px;
}

span.date-error-block {
  bottom: -57px;
  
  @media screen and (max-width: 767px) {
    bottom: -40px;
  }
}

label.error:before, span.error-help-block:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid #f2dede;
  width: 0;
  height: 0;
  position: absolute;
  top: -6px;
  right: 7px;
  content: "";
}

.wysiwyg-block {
  label.error, span.error-help-block {
    bottom: unset;
    top: -11px;
  }

  label.error:before, span.error-help-block:before {
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #f2dede;
    border-bottom: unset;
    width: 0;
    height: 0;
    position: absolute;
    top: unset;
    bottom: -6px;
    right: 7px;
    content: "";
  }
}

.user-role {
  label.error, span.error-help-block {
    bottom: unset;
    top: -9px;
    right: unset;
    left: 55px;
  }

  label.error:before, span.error-help-block:before {
    border-left: unset;
    border-right: 7px solid #f2dede;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    width: 0;
    height: 0;
    position: absolute;
    top: unset;
    bottom: 4px;
    right: unset;
    left: -7px;
    content: "";
  }
}

.checkboxes-array {
  .checkbox {
    label {
      color: #333333;
    }
  }
}

#article-text.has-error-own {
  label {
    color: #a94442;
  }
}

.has-error-own .form-control {
  border-color: #a94442;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  color: #a94442;
  /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(169, 68, 66, .6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(169, 68, 66, .6);*/
}

.has-error-own .input-group-addon {
  border-left-style: none;
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
  border-color: #a94442;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset;
  background-color: #fff8f8;
  color: #a94442;
  /*-webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(169, 68, 66, .6);
        box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(169, 68, 66, .6);*/
}

.has-success {
  .error-help-block {
    display: none !important;
  }
}

.input-group {
  .input-group-addon {
    .form-control {
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
  }
}

.has-error, .has-error-own {
  .input-group-addon {
    select {
      color: #a94442;
      background-color: #f2dede;
      border-color: #a94442;
    }
  }
}

.has-success, .has-success-own {
  .input-group-addon {
    select {
      color: #3c763d;
      background-color: #dff0d8;
      border-color: #3c763d;
    }
  }
}

.has-error-own {
  .control-label {
    color: #a94442;
  }
}

.error-tab {
  &:after {
    position: absolute;
    content: "";
    width: 0;
    height: 3px;
    background-color: #a94442;
    bottom: 0;
    left: 0;
    width: calc(100% - 2px);
  }
}

#language-versions-pane {
  .error-help-block, .error {
    right: unset;
    left: 0;
    bottom: unset;
    top: -40px;

    &:before {
      display: none;
    }
  }
}

#copy-accessories-form {
  .error-help-block {
    right: unset;
    left: 0;
    bottom: -78px;

    &:before {
      display: none;
    }
  }

  .has-success {
    label {
      color: #333333;
    }
  }

  .has-error, .has-error-own {
    label {
      color: #333333;
    }
  }
}

.checkbox-left-error {
  position: absolute;
  z-index: 10;
  padding: 3px 8px;
  margin-top: 7px;
  border-radius: 4px;
  font-size: 12px;
  bottom: -30px;
  background-color: #f2dede;
  color: #a94442;
  font-weight: 400;
  clear: both;
}

.checkbox-left-error:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 6px solid #f2dede;
  width: 0;
  height: 0;
  position: absolute;
  top: -6px;
  left: 7px;
  content: "";
}

.checkbox-left-top-error {
  position: absolute;
  z-index: 10;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  top: -28px;
  left: 0;
  background-color: #f2dede;
  color: #a94442;
  font-weight: 400;
  clear: both;
}

.checkbox-left-top-error:before {
  border-left: 7px solid transparent;
  border-right: 7px solid transparent;
  border-top: 6px solid #f2dede;
  width: 0;
  height: 0;
  position: absolute;
  bottom: -6px;
  left: 7px;
  content: "";
}

.checkbox-array-error {
  display: inline-block;
  padding: 3px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-bottom: 15px;
  background-color: #f2dede;
  color: #a94442;
  font-weight: 400;
  clear: both;
}

#password_confirmation+.error {
  display: none !important;
}